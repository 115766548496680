import { TwMainColor } from "data/types";
import { ApprovalStatus } from "models/commons";

export function getColorForStatus(status: ApprovalStatus): TwMainColor {
    switch (status) {
        case 'APPROVED':
            return 'green';
        case 'REJECTED':
            return 'red';
        case 'PENDING':
            return 'yellow';
        default:
            return 'gray';
    }
}