// Define the SortDirection enum
export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

// Define the NumericRange type
export interface NumericRange {
  min: number | null;
  max: number | null;
}

// Define the PagingAndSortingFilter interface
export interface PagingAndSortingFilter {
  page: number;
  paginated: boolean;
  pageSize: number;
  sortField: string;
  direction: SortDirection;
  keyword?: string;
}