

import { useStudentRegistrations } from "hooks/users/useUser";
import { PagingAndSortingFilter } from "models/filters";
import { StudentRegistrationRequest } from "models/users";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { downloadDocument, reviewStundetRegistration } from "services/user-service";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ConfirmationModal } from "shared/Modal/ConfirmationModal";
import Pagination from "shared/Pagination/Pagination";
import { getBadgeColorForAproovalStatus } from "utils/badeColors";
import { getApprovalStatus } from "utils/getApprovalStatus";
import { getBasicFilterFromSearchParams } from "utils/queryParamFilterUtils";

const StudentRegistrationRequests = () => {
    const { t } = useTranslation();
    const [changeTrigger, setChangeTrigger] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState<PagingAndSortingFilter>({} as PagingAndSortingFilter);
    const [currentPage, setCurrentPage] = useState(0);
    const { data, error, isLoading } = useStudentRegistrations(filter, [filter, changeTrigger]);

    function triggerChange() {
        setChangeTrigger(changeTrigger + 1);
    }

    useEffect(() => {
        if (data) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [data]);

    useEffect(() => {
        const newFilter = getBasicFilterFromSearchParams(searchParams);
        setFilter(newFilter);
        if (newFilter.page) {
            setCurrentPage(newFilter.page);
        }
    }, [searchParams]);


    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleRegistrationReview = (item: StudentRegistrationRequest, approved: boolean) => {
        reviewStundetRegistration(item.externalId, approved).then(() => {
            triggerChange();
        }).catch(() => {
            console.log("Error");
        });
    }


    const renderSection1 = () => {
        return (
            <div className="space-y-6 sm:space-y-8">
                <div className="group relative  bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden p-10">
                    <div className="relative overflow-x-auto sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 rounded-3xl">
                                <tr>
                                    <th scope="col" className="px-6 py-3" >{t("user.firstName", "First Name")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("user.lastName", "Last Name")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("user.email", "Email")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("page.registrationRequests.table.status", "Status")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("registrationRequest.proofOfStudy", "Proof of Study")}</th>
                                    <th scope="col" className="px-6 py-3" ></th>
                                </tr>
                            </thead>

                            <tbody>
                                {data?.payload.map((item) => (
                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                        <td className="px-6 py-4" >{item.student.firstName}</td>
                                        <td className="px-6 py-4">{item.student.lastName}</td>
                                        <td className="px-6 py-4">{item.student.email}</td>
                                        <td className="px-6 py-4">
                                            <Badge name={getApprovalStatus(t, item.status)} color={getBadgeColorForAproovalStatus(item.status)} />
                                        </td>
                                        <td className="px-6 py-4">
                                            <ButtonSecondary onClick={() => downloadDocument(item.proofOfStudyDocument)}>{t("common.button.download", "Download")}</ButtonSecondary>
                                        </td>
                                        <td className="px-6 py-4">
                                            {item.status === "PENDING" && (
                                                <ConfirmationModal confirmText={t("registrationRequest.actions.review.approve", "Approve")} cancelText={t("registrationRequest.actions.review.reject", "Reject")} title={t("registrationRequest.actions.review.title", "Review Application")}
                                                    message={t("registrationRequest.actions.review.description", "Please confirm your decision on this registration request")}
                                                    onConfirm={() => handleRegistrationReview(item, true)} onCancel={() => handleRegistrationReview(item, false)}
                                                    renderTrigger={(openModal: Function) => (
                                                        <ButtonPrimary onClick={() => openModal()}>{t("registrationRequest.actions.review.review", "Review")}</ButtonPrimary>
                                                    )} />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {data && data.totalPages > 1 && (
                        <div className="flex mt-16 justify-center items-center">
                            <Pagination
                                totalPages={data.totalPages}
                                currentPage={currentPage}
                                setCurrentPage={handlePageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div>
            {renderSection1()}
        </div>
    );
};

export default StudentRegistrationRequests;


