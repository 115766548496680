import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { useApplications } from "hooks/applications/useApplication";
import { Application } from "models/applications";
import { ApprovalStatus } from "models/commons";
import { PagingAndSortingFilter } from "models/filters";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { reviewApplicationByAdmin } from "services/application-service";
import { Alert } from "shared/Alert/Alert";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ConfirmationModal } from "shared/Modal/ConfirmationModal";
import Pagination from "shared/Pagination/Pagination";
import { getApprovalStatus } from "utils/getApprovalStatus";
import { getColorForStatus } from "utils/getColorForApprovalStatus";


const AdminApplicationReviewPage = () => {
    const { t } = useTranslation();
    const [pagination, setPagination] = useState<PagingAndSortingFilter>({} as PagingAndSortingFilter);
    const { data, error, isLoading } = useApplications(pagination, [pagination]);
    const [currentPage, setCurrentPage] = useState(0);
    const [apiError, setApiError] = useState<string>();

    useEffect(() => {
        if (data) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [data]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setPagination({ ...pagination, page });
    };

    const handleApplicationReview = (item: Application, approved: boolean) => {
        setApiError(undefined)
        reviewApplicationByAdmin(item.externalId, approved ? ApprovalStatus.APPROVED : ApprovalStatus.REJECTED).then(() => {
            setPagination({ ...pagination, page: currentPage });
        }).catch((error) => {
            console.log(error)
            setApiError(error.response.data.error.message);
        });
    }

    const renderSection1 = () => {


        return (

            <div className="space-y-6 sm:space-y-8">
                <Helmet>
                    <title>{t("page.applicationReview.helmet", "Applications")}</title>
                </Helmet>
                <div>
                    <h2 className="text-3xl font-semibold">{t("page.applicationReview.title", "Applications")}</h2>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {apiError && (
                    <div className="w-full">
                        <Alert type="error" >
                            {apiError}
                        </Alert>
                    </div>
                )}

                <div className="group relative  bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden p-10">



                    <div className="relative overflow-x-auto sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 rounded-3xl">
                                <tr>
                                    <th scope="col" className="px-6 py-3" >{t("page.applicationReview.table.student", "Student")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("page.applicationReview.table.listing", "Listing")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("page.applicationReview.table.status", "Status")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("page.applicationReview.table.review", "Review")}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data?.payload.map((item) => (
                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                        <td className="px-6 py-4" >
                                            <div className="flex flex-col">
                                                <span>
                                                    {item.student.firstName}
                                                </span>
                                                <span>
                                                    {item.student.lastName}
                                                </span>
                                                <span>
                                                    {item.student.email}
                                                </span>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex flex-row space-x-2">
                                                <span >
                                                    {item.listing.title}

                                                </span>
                                                <Link target="_blank" rel="noopener noreferrer" to={`/listing/${item.listing.externalId}`} >
                                                    <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                                                </Link>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex flex-col space-y-2">
                                                <span>
                                                    {t("page.applicationReview.table.adminReview", "Admin Review:")} <Badge name={getApprovalStatus(t, item.administratorApprovalStatus)} color={getColorForStatus(item.administratorApprovalStatus)} />
                                                </span>
                                                {item.administratorApprovalStatus === ApprovalStatus.APPROVED && (
                                                    <span>
                                                        {t("page.applicationReview.table.landlordReview", "Landlord Review:")} <Badge name={getApprovalStatus(t, item.status)} color={getColorForStatus(item.status)} />
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            {item.administratorApprovalStatus === "PENDING" && (
                                                <ConfirmationModal confirmText={t("application.actions.review.approve", "Accept")} cancelText={t("application.actions.review.reject", "Reject")} title={t("application.actions.review.title", "Review Application")}
                                                    message={t("application.actions.review.description", "Please confirm your decision on this application")}
                                                    onConfirm={() => handleApplicationReview(item, true)} onCancel={() => handleApplicationReview(item, false)}
                                                    renderTrigger={(openModal: Function) => (
                                                        <ButtonPrimary onClick={() => openModal()}>{t("application.actions.review.review", "Review")}</ButtonPrimary>
                                                    )} />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {data && data.totalPages > 1 && (
                        <div className="flex mt-16 justify-center items-center">
                            <Pagination
                                totalPages={data.totalPages}
                                currentPage={currentPage}
                                setCurrentPage={handlePageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900 ">

            <div className="container pt-6 pb-24 lg:pb-32">
                {renderSection1()}
            </div>
        </div>
    );
};



export default AdminApplicationReviewPage;
