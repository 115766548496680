import { useGetData, usePostData } from "hooks/useData";
import { PagingAndSortingFilter } from "models/filters";
import { Listing, SearchFilter } from "models/listings";
import { PaginatedRespoonseList, ResponseObject } from "models/responses";

export const useListingSearch = (filter: SearchFilter, deps?: any[]) => {
    const endpoint = `/api/v1/listings/search`;

    const { data, error, isLoading } = usePostData<PaginatedRespoonseList<Listing>>(endpoint, filter, deps);

    return { data, error, isLoading };
};

export const useListings = (filter: PagingAndSortingFilter, deps?: any[]) => {
    const endpoint = `/api/v1/listings`;

    const { data, error, isLoading } = useGetData<PaginatedRespoonseList<Listing>>(endpoint, { params: filter || {} }, deps);

    return { data, error, isLoading };
};


export const useListing = (externalId: string, deps?: any[]) => {
    const endpoint = `/api/v1/listings/${externalId}`;

    const { data, error, isLoading } = useGetData<ResponseObject<Listing>>(endpoint, undefined, deps);

    return { data, error, isLoading };
};
