import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export interface CommonLayoutProps {
  children?: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900">
      <div className="border-b border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800">
        <div className="container">
          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
            <NavLink
              to="/account"
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${!isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t("page.account.tab", "Account")}
            </NavLink>
            <NavLink
              to="/change-password"
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${!isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t("page.changePassword.tab", "Change Password")}
            </NavLink>
          </div>
        </div>
      </div>
      <div className="container pt-6 pb-24 lg:pb-32">{children}</div>
    </div>
  );
};

export default CommonLayout;
