import IBecomeAnAuthor from "images/BecomeAnAuthorImg.png";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Alert } from "shared/Alert/Alert";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";

const ApplicationSuccessPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="nc-ApplicationSuccessPage">
      <Helmet>
        <title>{t("page.applicationSuccess.title", "Application successful")}</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-2">
          <NcImage src={IBecomeAnAuthor} />
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium py-8">
            <Alert type="success">
              {t("page.applicationSuccess.description", "Your application was successfully submitted!")}
            </Alert>
          </span>
          <div>
            <ButtonPrimary href="/my-applications"> {t("page.applicationSuccess.button.myApplications", "My Applications")}</ButtonPrimary>
          </div>
        </header>
      </div>
    </div>
  );
}

export default ApplicationSuccessPage;
