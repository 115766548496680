import ListingSearchFormMobile from "components/ListingSearchFormMobile/ListingSearchFormMobile";
import { SearchFilter } from "models/listings";
import { useAuth } from "providers/AuthProvider";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import Navigation from "shared/Navigation/Navigation";
import { NavItemType } from "shared/Navigation/NavigationItem";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import ncNanoId from "utils/ncNanoId";
import { getSearchFilterFromSearchParams, populateSearchParamsFromSearchFilter } from "utils/queryParamFilterUtils";
import AvatarDropdown from "./AvatarDropdown";
import LangDropdown from "./LangDropdown";
import RegistrationDropdown from "./RegistrationDropdown";

export interface MainNav1Props {
  className?: string;
}

const MainNav: FC<MainNav1Props> = ({ className = "" }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<SearchFilter>({} as SearchFilter);
  const { currentUser } = useAuth();
  const isLoggedIn = !!currentUser;

  useEffect(() => {
    if (location.pathname === "/") {
      const newFilter = getSearchFilterFromSearchParams(searchParams);
      setFilter(newFilter);
    }
  }, [searchParams]);

  const handleFilterChange = (newFilter: SearchFilter) => {
    // setFilter(newFilter);
    populateSearchParamsFromSearchFilter(searchParams, newFilter);
    setSearchParams(searchParams);
  };


  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>
        {location.pathname === "/" && (
          <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
            <ListingSearchFormMobile filter={filter} onFilterChange={handleFilterChange} />
          </div>
        )
        }
        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            <SwitchDarkMode />
            <div className="px-1" />
            <LangDropdown />
            <div className="px-1" />
            {isLoggedIn ? (
              <AvatarDropdown userName={currentUser.firstName} />
            ) : (
              <>
                <RegistrationDropdown />
                <div className="px-1" />
                <ButtonPrimary href="/login">{t("common.button.login", "Log In")}</ButtonPrimary>
              </>
            )}
          </div>
          <div className="flex xl:hidden items-center">
            <SwitchDarkMode />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div >
    </div >
  );
};

export default MainNav;
