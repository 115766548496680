import { Popover, Transition } from "@headlessui/react";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/solid";
import { NumericRange } from "models/filters";
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { renderAreaRange } from "utils/renderAreaRange";

export interface AreaRangeInputProps {
  onAreaRangeChanged: (areaRange: NumericRange) => void;
  areaRange: NumericRange;
  fieldClassName?: string;
}

const AreaRangeInput: FC<AreaRangeInputProps> = ({
  onAreaRangeChanged,
  areaRange,
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const { t } = useTranslation();

  const [internalAreaRange, setInternalAreaRange] = useState<NumericRange>(areaRange);

  useEffect(() => {
    setInternalAreaRange(areaRange);
  }, [areaRange]);


  function setRangeAreas(min: number | null, max: number | null): void {
    setInternalAreaRange({
      min: min,
      max: max,
    });
    onAreaRangeChanged({
      min: min,
      max: max,
    });
  }



  return (
    <Popover className="flex relative flex-[1.3]">
      {({ open, close }) => (
        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none cursor-pointer ${open ? "nc-hero-field-focused" : ""
              }`}
          >
            <Popover.Button
              className={`flex-1 flex text-left items-center focus:outline-none ${fieldClassName} space-x-3 `}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <ArrowsPointingOutIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold truncate">
                  {renderAreaRange(t, internalAreaRange)}
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light ">
                  {t("page.search.field.area.description", "Choose area range")}
                </span>
              </div>
            </Popover.Button>
          </div>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 bg-white dark:bg-neutral-800"></div>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 lg:right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <div className="relative flex flex-col space-y-8">
                <div className="flex justify-between space-x-3">
                  <div>
                    <label
                      htmlFor="minArea"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    >
                      {t("common.min", "Min")}
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      </div>
                      <input
                        type="number"
                        name="minArea"
                        id="minArea"
                        className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 dark:border-neutral-500 rounded-full text-neutral-900 dark:text-neutral-200 bg-transparent"
                        value={internalAreaRange.min || undefined}
                        onChange={(e) => setRangeAreas(e.target.value ? parseInt(e.target.value) : null, internalAreaRange.max)}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="maxArea"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    >
                      {t("common.max", "Max")}
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      </div>
                      <input
                        type="number"
                        name="maxArea"
                        id="maxArea"
                        className="focus:ring-primary-500 focus:border-priring-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 dark:border-neutral-500 rounded-full text-neutral-900 dark:text-neutral-200 bg-transparent"
                        value={internalAreaRange.max || undefined}
                        onChange={(e) => setRangeAreas(internalAreaRange.min, e.target.value ? parseInt(e.target.value) : null)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default AreaRangeInput;
