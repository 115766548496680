import { BaseModel } from "./base-entity";
import { ApprovalStatus } from "./commons";
import { Document } from "./document";
export interface LandlordRegistration {
    email: string;
    firstName: string;
    lastName: string;
}

export interface AdminRegistration {
    email: string;
    firstName: string;
    lastName: string;
}

export interface StudentRegistration {
    email: string;
    firstName: string;
    lastName: string;
    proofOfStudy: File;
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    REGISTRATION_PENDING = 'REGISTRATION_PENDING'
}

export interface User extends BaseModel {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    status: UserStatus;
}

export interface UserCredentails {
    email: string;
    password: string;
}

export interface FinishRegistrationRequest {
    email: string;
    password: string;
    registrationToken: string;
}

export interface Token {
    token: string;
}

export interface StudentRegistrationRequest extends BaseModel {
    student: User;
    status: ApprovalStatus;
    proofOfStudyDocument: Document;
}

export interface LandlordRegistrationRequest extends BaseModel {
    landlord: User;
    status: ApprovalStatus;
}