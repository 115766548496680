
import ListingSearchForm from "components/ListingSearchForm/ListingSearchForm";
import imagePng from "images/hero-right-3.webp";
import { SearchFilter } from "models/listings";
import { useTranslation } from "react-i18next";


interface SearchFormSectionProps {
    className?: string;
    filter: SearchFilter;
    filterChanged: (filter: SearchFilter) => void;
}


const SearchFormSection = ({ className, filter, filterChanged }: SearchFormSectionProps) => {
    const { t } = useTranslation();

    return (

        <div
            className={`nc-SectionHero3 relative ${className}`}
            data-nc-id="SectionHero3"
        >
            <div className="absolute z-10 inset-x-0 top-[10%] sm:top-[15%] text-center flex flex-col items-center mx-auto space-y-4 lg:space-y-5 xl:space-y-8">
                <h2 className="font-bold text-primary text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[115%] ">
                    {t("page.search.title","New generation \n of renting")}
                </h2>
                <div className="hidden lg:block mt-10 w-full">
                    <div className={`nc-HeroRealEstateSearchForm w-full px-8 py-5 lg:py-0 ${className}`}>
                        <ListingSearchForm filter={filter} onFilterChange={((newFilter) => filterChanged(newFilter))} />
                    </div>

                </div>
            </div>
            <div className="relative sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
                <img
                    className="inset-0 object-cover rounded-xl"
                    src={imagePng}
                    alt="hero"
                />
            </div>
        </div>
    );
}

export default SearchFormSection;