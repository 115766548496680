import Label from "components/Label/Label";
import { useAuth } from "providers/AuthProvider";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Input from "shared/Input/Input";
import { getUserRole } from "utils/getUserRole";
import CommonLayout from "./CommonLayout";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();


  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>{t("page.account.helmet", "Account")}</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">{t("page.account.title", "Account Information")}</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <Label>{t("user.firstName", "First Name")}</Label>
                <Input disabled={true} className="mt-1.5" value={currentUser?.firstName} />
              </div>
              <div>
                <Label>{t("user.lastName", "Last Name")}</Label>
                <Input disabled={true} className="mt-1.5" value={currentUser?.lastName} />
              </div>
              <div>
                <Label>{t("user.email", "Email Address")}</Label>
                <Input disabled={true} className="mt-1.5" value={currentUser?.email} />
              </div>
              <div>
                <Label>{t("user.role", "Role")}</Label>
                <Input disabled={true} className="mt-1.5" value={getUserRole(t, currentUser?.role)} />
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
