import { FC } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import twFocusClass from "utils/twFocusClass";

export interface PaginationProps {
  className?: string;
  totalPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  className = "",
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  const renderItem = (page: number, index: number) => {
    if (index === currentPage) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`cursor-pointer inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {page + 1}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <span
        key={index}
        className={`cursor-pointer inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        onClick={() => setCurrentPage(page)}
      >
        {page + 1}
      </span>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {Array.from({ length: totalPages }, (_, i) => i).map(renderItem)}
    </nav>
  );
};

export default Pagination;
