import React from "react";
import { Helmet } from "react-helmet-async";
import ReactLoading from "react-loading";

const LoadingPage: React.FC = () => (
    <div className="nc-LoadingPage">
        <Helmet>
            <title>404 || Rentals</title>
        </Helmet>
        <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
            {/* HEADER */}
            <header className="text-center max-w-2xl mx-auto space-y-2">
                <div className="container h-full relative pt-5 pb-16 lg:pb-20 lg:pt-5">
                    <ReactLoading type='bars' color="#4f46e5" width="100px" />
                </div>
            </header>
        </div>
    </div>
);

export default LoadingPage;
