import { FC, ReactNode } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";

interface Props {
  children: ReactNode;
  images?: ListingGalleryImage[];
}
const DetailPagetLayout: FC<Props> = ({ children, images }) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  const getImageGalleryListing = () => {
    return images;
  };

  return (
    <div className="ListingDetailPage pb-8">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">{children}</div>
    </div >
  );
};

export default DetailPagetLayout;
