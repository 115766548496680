import GallerySlider from "components/GallerySlider/GallerySlider";
import { Listing } from "models/listings";
import { FC, PropsWithChildren, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import convertImageLocationToUrl from "utils/convertImageLocationToUrl";

type CommonListingCardProps = PropsWithChildren & {
  className?: string;
  data: Listing;
  href: string;
  badges?: ReactNode
}


const CommonListingCard: FC<CommonListingCardProps> = ({
  className = "",
  data,
  href,
  children,
  badges
}) => {
  const { t } = useTranslation();
  const renderSliderGallery = () => {
    return (

      <div className="flex-shrink-0 p-3 w-full sm:w-64 md:min-w-56  xl:min-w-96  "  >

        {data.images && data.images.length > 0 && (
          <GallerySlider
            ratioClass="aspect-w-4 aspect-h-3"
            galleryImgs={data.images.map((img: any) => convertImageLocationToUrl(img.location, 500, 500))}
            className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
            uniqueID={`ListingCard_${data.externalId}`}
            href={href}
          />
        )}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2">
        <div className="flex items-center space-x-2">
          <span>
            <i className="las la-bed text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data.bedrooms} {t("listing.bedrooms", "bedrooms")}
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span>
            <i className="las la-bath text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data.bathrooms} {t("listing.bathrooms", "bathrooms")}
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span>
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data.areaSqm} {t("common.m2", "Sq. m")}
          </span>
        </div>
      </div>
    );
  };

  const getLocationText = () => {
    let address = data.address.city;
    if (data.address.district) {
      address += `, ${data.address.district}`;
    }
    if (data.address.street) {
      address += `, ${data.address.street}`;
    }
    return address;
  }

  const renderLocation = () => {
    return (
      <div className="">
        <div className="flex items-center space-x-2">
          <span className="">
            <i className="las la-map"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {getLocationText()}
          </span>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          <div className="inline-flex space-x-3">
            <Badge
              name={
                <div className="flex items-center">
                  <i className="las la-share-alt"></i>
                  <span className="ml-1">{t(`listing.propertyTypes.${data.unitType}`, data.unitType)}</span>
                </div>
              }
              color={data.unitType === 'APARTMENT' ? 'indigo' : data.unitType === 'HOUSE' ? 'green' : 'yellow'}
            />
            {badges}
          </div>
          <div className="flex items-center space-x-2">
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-2">{data.title}</span>
            </h2>
          </div>
          {renderLocation()}
          {renderTienIch()}
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end sm:flex-row">
            <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
              {`${data.basicRent} EUR`}
            </span>
            <div className="flex items-center space-x-2">
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:border-primary-500 transition-shadow ${className}`}
      data-nc-id="ListingCard"
    >
      <Link to={href} className="absolute inset-0"></Link>
      <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}

      </div>
    </div>
  );
};

export default CommonListingCard;
