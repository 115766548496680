import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import LandlordRegistrationRequests from "./LandlordRegistrationRequests";
import StudentRegistrationRequests from "./StudentRegistrationRequests";

const UserRegistrationRequests = () => {
    const { t } = useTranslation();
    const tabs = [t("page.registrationRequests.students", "Students"), t("page.registrationRequests.landlords", "Landlords")];
    const renderSection1 = () => {
        return (
            <>
                <Helmet>
                    <title>{t("page.registrationRequests.helmet", "Registration Requests")}</title>
                </Helmet>
                <div>
                    <h2 className="text-3xl font-semibold">{t("page.registrationRequests.title", "Registration Requests")}</h2>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 pb-2"></div>
                <div className="mt-4">

                    <Tab.Group>
                        <Tab.List className="flex space-x-1 overflow-x-auto">
                            {tabs.map((item) => (
                                <Tab key={item} as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${selected
                                                ? "bg-secondary-6000 text-secondary-50 "
                                                : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                                                } `}
                                        >
                                            {item}
                                        </button>
                                    )}
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels className="mt-4">
                            <Tab.Panel >

                                <StudentRegistrationRequests />
                            </Tab.Panel>
                            <Tab.Panel>
                                <LandlordRegistrationRequests />
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </>
        );
    };

    return (
        <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900 ">
            <div className="container pt-6 pb-24 lg:pb-32">
                {renderSection1()}
            </div>
        </div>
    );
};

export default UserRegistrationRequests;
