import { useListingSearch } from "hooks/listings/useListing";
import { SearchFilter } from "models/listings";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ListResultsInformation from "shared/ListResultsInformation/ListResultsInformation";
import { getSearchFilterFromSearchParams, populateSearchParamsFromSearchFilter } from "utils/queryParamFilterUtils";
import SearchSection from "./SearchFormSection";
import SectionGridFilterCard from "./SectionGridFilterCard";
export interface SearchPageProps {
  className?: string;

}

const SearchPage: FC<SearchPageProps> = ({
  className = "",
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<SearchFilter>({} as SearchFilter);
  const [currentPage, setCurrentPage] = useState(0);
  const { data, error, isLoading } = useListingSearch(filter, [filter]);

  useEffect(() => {
    const newFilter = getSearchFilterFromSearchParams(searchParams);
    setFilter(newFilter);
    if (newFilter.page) {
      setCurrentPage(newFilter.page);
    }
  }, [searchParams]);

  useEffect(() => {
    if (data) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentPage]);

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-cyan-blueGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  function currentPageChanged(page: number): void {
    setCurrentPage(page);
    searchParams.set("page", String(page));
    setSearchParams(searchParams);
  }

  function filterChanged(filter: SearchFilter): void {
    populateSearchParamsFromSearchFilter(searchParams, filter);
    setSearchParams(searchParams);
  }



  return (
    <>
      <div
        className={`nc-SearchPage relative overflow-hidden ${className}`}
        data-nc-id="SearchPage"
      >
        <Helmet>
          <title>{t("page.search.helmet", "Listings")}</title>
        </Helmet>

        <div className="container relative">
          {/* SECTION SEARCH */}
          <SearchSection filter={filter} filterChanged={filterChanged} className={className} />
          <ListResultsInformation label={t("page.search.results.description", "Number of properties matching your criteria:")} totalResults={data?.totalSize} isLoading={isLoading} error={error} className="py-4 w-full" />
          {/* SECTION LISTINGS*/}
          <SectionGridFilterCard data={data} currentPage={currentPage} onCurrentPageChange={currentPageChanged} className="py-4 lg:py-4" />
        </div>
      </div>
    </>
  );
};

export default SearchPage;
