import { NumericRange } from "models/filters";
import { SearchFilter, UnitType } from "models/listings";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { renderAreaRange } from "utils/renderAreaRange";
import { renderPriceRange } from "utils/renderPriceRange";
import AreaRangeInput from "./AreaRangeInput";
import PriceRangeInput from "./PriceRangeInput";
import PropertyTypeSelect from "./PropertyTypeSelect";
import SearchKeywordInput from "./SearchKeywordInput";

export interface ListingSearchFormProps {
  filter: SearchFilter;
  onFilterChange?: (filter: SearchFilter) => void;
}

const ListingSearchForm = ({ filter, onFilterChange }: ListingSearchFormProps) => {
  const { t } = useTranslation();
  const [searchKeyword, setSearchKeyword] = useState<string | undefined>(filter.keyword);
  const [priceRange, setPriceRange] = useState<NumericRange>(filter.price || { min: null, max: null });
  const [areaRange, setAreaRange] = useState<NumericRange>(filter.area || { min: null, max: null });
  const [unitTypes, setUnitTypes] = useState<UnitType[]>(filter.unitTypes || []);

  const [fieldNameShow, setFieldNameShow] = useState<string>("location");

  useEffect(() => {
    setSearchKeyword(filter.keyword);
    setPriceRange(filter.price || { min: null, max: null });
    setAreaRange(filter.area || { min: null, max: null });
    setUnitTypes(filter.unitTypes || []);
  }, [filter]);


  function priceRangeChanged(newRange: NumericRange): void {
    setPriceRange(newRange);
    if (onFilterChange) {
      onFilterChange?.({ keyword: searchKeyword, price: newRange, area: areaRange, unitTypes: unitTypes } as SearchFilter);
    }
  }

  function areaRangeChanged(newRange: NumericRange): void {
    setAreaRange(newRange);
    if (onFilterChange) {
      onFilterChange?.({ keyword: searchKeyword, price: priceRange, area: newRange, unitTypes: unitTypes } as SearchFilter);
    }
  }

  const onSearchKeywordChanged = (keyword: string): void => {
    setSearchKeyword(keyword);
    if (onFilterChange) {
      onFilterChange({ keyword, price: priceRange, area: areaRange, unitTypes } as SearchFilter);
    }
  }


  function handleOnPropertyTypeChange(data: UnitType[]): void {
    setUnitTypes(data);
    if (onFilterChange) {
      onFilterChange({ keyword: searchKeyword, price: priceRange, area: areaRange, unitTypes: data } as SearchFilter);
    }
  }

  const renderInputLocation = () => {
    const isActive = fieldNameShow === "location";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("location")}
          >
            <span className="text-neutral-400">{t("page.search.field.search.title", "Search")}</span>
            <span>{searchKeyword || ""}</span>
          </button>
        ) : (
          <SearchKeywordInput
            headingText={t("page.search.field.search.description", "Search")}
            searchKeyword={searchKeyword}
            onValueChange={onSearchKeywordChanged}
          />
        )}
      </div>
    );
  };

  const renderInputPropertyType = () => {
    const isActive = fieldNameShow === "propertyType";
    let unitTypeText = "";
    if (unitTypes && unitTypes.length > 0) {
      unitTypeText = unitTypes.map(type => t(`listing.propertyTypes.${type}`, type)).join(", ");
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("propertyType")}
          >
            <span className="text-neutral-400">{t("page.search.field.propertyType.title", "Property Type")}</span>
            <span className="truncate ml-5">
              {unitTypeText || t("common.any", "Any")}
            </span>
          </button>
        ) : (
          <PropertyTypeSelect
            unitTypes={unitTypes}
            onChange={handleOnPropertyTypeChange}
          />
        )}
      </div>
    );
  };

  const renderInputPrice = () => {
    const isActive = fieldNameShow === "price";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("price")}
          >
            <span className="text-neutral-400">{t("page.search.field.price.title", "Price")}</span>
            <span>
              {renderPriceRange(t, priceRange)}
            </span>
          </button>
        ) : (
          <PriceRangeInput
            priceRange={priceRange}
            onChange={priceRangeChanged}
          />
        )}
      </div>
    );
  };


  const renderInputArea = () => {
    const isActive = fieldNameShow === "area";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("area")}
          >
            <span className="text-neutral-400">{t("page.search.field.area.title", "Area")}</span>
            <span>
              {renderAreaRange(t, areaRange)}
            </span>
          </button>
        ) : (
          <AreaRangeInput
            areaRange={areaRange}
            onChange={areaRangeChanged}
          />
        )}
      </div>
    );
  };


  return (
    <div>
      <div className="w-full space-y-5">
        {/*  */}
        {renderInputLocation()}
        {/*  */}
        {renderInputPropertyType()}
        {/*  */}
        {renderInputArea()}
        {/*  */}
        {renderInputPrice()}
      </div>
    </div>
  );
};

export default ListingSearchForm;
