import { AxiosResponse } from "axios";
import { Listing } from "models/listings";
import { MessageResponse, ResponseObject } from "models/responses";
import apiClient from "services/api-client";


export function getById(externalId: string): Promise<AxiosResponse<ResponseObject<Listing>>> {
    return apiClient.get<ResponseObject<Listing>>(`/api/v1/listings/${externalId}`);
}

export function createListing(listing: Listing): Promise<AxiosResponse<ResponseObject<Listing>>> {

    return apiClient.post<ResponseObject<Listing>>("/api/v1/listings", listing);
}


export function updateListing(externalId: string, listing: Listing): Promise<AxiosResponse<ResponseObject<Listing>>> {
    return apiClient.put<ResponseObject<Listing>>(`/api/v1/listings/${externalId}`, listing);
}

export function updateStatus(externalId: string, status: string): Promise<AxiosResponse<ResponseObject<Listing>>> {
    return apiClient.put<ResponseObject<Listing>>(`/api/v1/listings/${externalId}/status`, null, { params: { status: status } });
}

export function uploadImages(externalId: string, files: File[]): Promise<AxiosResponse<ResponseObject<MessageResponse>>> {

    const formData = new FormData();
    files.forEach(file => {
        formData.append("files", file);
    });

    return apiClient.post<ResponseObject<MessageResponse>>(`/api/v1/listings/${externalId}/images`,
        formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function deleteImage(listingExternalId: string, imageExternalId: string): Promise<AxiosResponse<ResponseObject<MessageResponse>>> {
    return apiClient.delete<ResponseObject<MessageResponse>>(`/api/v1/listings/${listingExternalId}/images/${imageExternalId}`);
}