import { LandlordRegistration } from "models/users";
import { FC, FormEvent, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { registerLandlord } from "services/user-service";
import { Alert } from "shared/Alert/Alert";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

export interface PageSignUpProps {
  className?: string;
}


const PageLandlordSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({} as LandlordRegistration);
  const [errors, setErrors] = useState<{ email?: string; firstName?: string; lastName?: string }>({});
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string | null>(null);

  function validateForm(): boolean {
    const newErrors: { email?: string; firstName?: string; lastName?: string } = {};
    if (!formData.email) {
      newErrors.email = t("common.validation.email", "Email is required");
    }
    if (!formData.firstName) {
      newErrors.firstName = t("common.validation.required", "First name is required");
    }
    if (!formData.lastName) {
      newErrors.lastName = t("common.validation.required", "Last name is required");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setApiError(null);
      registerLandlord(formData).then((res) => {
        console.log(res.data);
        // navigate to signup success page
        navigate("/signup-success");
      }).catch((err) => {
        setApiError(err.response.data.error.message);

      });
    }
  };


  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }


  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>{t("page.signup.helmet", "Sign Up")}</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("page.signup.title", "Sign Up")}
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t("common.field.email", "Email address")}
              </span>
              <Input
                type="email"
                className="mt-1"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t("user.firstName", "First Name")}
              </span>
              <Input
                type="text"
                name="firstName"
                className="mt-1"
                value={formData.firstName}
                onChange={handleChange}
              />
              {errors.firstName && <p className="text-red-500">{errors.firstName}</p>}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t("user.lastName", "Last Name")}
              </span>
              <Input
                type="text"
                name="lastName"
                className="mt-1"
                value={formData.lastName}
                onChange={handleChange}
              />
              {errors.lastName && <p className="text-red-500">{errors.lastName}</p>}
            </label>
            <ButtonPrimary type="submit"> {t("page.signup.button.signup", "Sign Up")}</ButtonPrimary>
          </form>

          {/* ERROR */}
          {apiError &&
            <Alert type="error">
              {apiError}
            </Alert>
          }
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t("page.signup.alreadyHaveAccount", "Already have an account?")} {` `}
            <Link to="/login"> {t("common.button.login", "Log In")}</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLandlordSignUp;


