import { Address } from "models/listings";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { PropertyLocationValidationError } from "./PageManageListing";

export interface ManageListingLocationProps {
  formData: Address;
  onNextPage?: (formData: Address) => void;
  onBackPage?: (formData: Address) => void;
  index: string;
  maxIndex: string;
  validationErrors: PropertyLocationValidationError;
}

const ManageListingLocation: FC<ManageListingLocationProps> = ({ onBackPage, onNextPage, formData, index, maxIndex, validationErrors }) => {
  const { t } = useTranslation();
  const [internalFormData, setInternalFormData] = useState<Address>(formData);

  const handleOnNextPage = () => {
    if (onNextPage)
      onNextPage(internalFormData);
  }

  const handleOnBackPage = () => {
    if (onBackPage)
      onBackPage(internalFormData);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInternalFormData({
      ...internalFormData,
      [e.target.name]: e.target.value
    });
  }


  return (
    <CommonLayout
      index={index}
      maxIndex={maxIndex}
      onNextPage={handleOnNextPage}
      onBackPage={handleOnBackPage}
    >
      <>
        <h2 className="text-2xl font-semibold">{t("page.manageListings.location.title", "Listing Location")}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-5">
            <FormItem className="md:col-span-3" label={t("page.manageListings.location.field.street.label", "Street")}>
              <Input name="street" value={internalFormData.street} onChange={handleChange} />
              {validationErrors.street && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.street}</span>}
            </FormItem>
            <FormItem label={t("page.manageListings.location.field.houseNumber.label", "House Number")}>
              <Input name="houseNumber" value={internalFormData.houseNumber} onChange={handleChange} />
              {validationErrors.houseNumber && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.houseNumber}</span>}
            </FormItem>

          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">

            <FormItem label={t("page.manageListings.location.field.city.label", "City")}>
              <Input name="city" value={internalFormData.city} onChange={handleChange} />
              {validationErrors.city && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.city}</span>}
            </FormItem>
            <FormItem label={t("page.manageListings.location.field.district.label", "City District")}>
              <Input name="district" value={internalFormData.district} onChange={handleChange} />
              {validationErrors.district && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.district}</span>}
            </FormItem>
            <FormItem label={t("page.manageListings.location.field.postalCode.label", "Postal Code")}>
              <Input name="postalCode" value={internalFormData.postalCode} onChange={handleChange} />
              {validationErrors.postalCode && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.postalCode}</span>}
            </FormItem>
            <FormItem label={t("page.manageListings.location.field.region.label", "Region")}>
              <Input name="region" value={internalFormData.region} onChange={handleChange} />
              {validationErrors.region && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.region}</span>}
            </FormItem>

          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default ManageListingLocation;
