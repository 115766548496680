import { APIProvider } from "@vis.gl/react-google-maps";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

//
import "rc-slider/assets/index.css";
// STYLE
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "./index.css";
import "./styles/index.scss";

//
import AuthProvider from "providers/AuthProvider";
import App from "./App";
import "./i18n/config.ts";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <APIProvider apiKey="">
        <AuthProvider>
          <App />
        </AuthProvider>
      </APIProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
