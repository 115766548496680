import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import IBecomeAnAuthor from "images/BecomeAnAuthorImg.png";
import { Alert } from "shared/Alert/Alert";
import { useTranslation } from "react-i18next";

const PageSignUpSuccess: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="nc-PageSignUpSuccess" >
      <Helmet>
        <title>Registration Success</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-2">
          <NcImage src={IBecomeAnAuthor} />
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium py-8">
            <Alert type="success">
              {t("page.signup.registration.success", "Registration was requested successfully")}
            </Alert>
          </span>
          <div>
            <ButtonPrimary href="/"> {t("page.signup.registration.returnToHome", "Return To Home")}</ButtonPrimary>
          </div>
        </header>
      </div>
    </div >
  );
}

export default PageSignUpSuccess;
