import FormItem from "containers/Listing/ListingManage/FormItem";
import { TwMainColor } from "data/types";
import { Listing, ListingStatus } from "models/listings";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { updateStatus } from "services/listing-service";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";
import Select from "shared/Select/Select";
import CommonListingCard from "./CommonListingCard";

export interface AdministrationListingCardProps {
  className?: string;
  data: Listing;
  href: string;
}


const AdministrationListingCard: FC<AdministrationListingCardProps> = ({
  className = "",
  data,
  href
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [listing, setListing] = useState<Listing>(data);
  const [status, setStatus] = useState<ListingStatus>(listing.status || ListingStatus.DRAFT);

  const renderChangeStatusModalContent = (close: Function) => {

    const handleStatusChange = (close: Function) => {
      updateStatus(listing.externalId, status).then(() => {
        setListing({ ...listing, status });
        close();
      }).catch((error) => {
        console.error(error);
      });
    }

    return (
      <>
        <div className="px-4 max-w-md mx-auto pt-14">
          {/* FORM */}
          <div className="space-y-8">
            {/* ITEM */}
            <FormItem
              label={t("listing.actions.changeStatus.label", "Change Status")}
              desc={t("listing.actions.changeStatus.description", "Change status of your listing - this will affect the visibility of your listing")}
            >
              <Select name="status" onChange={(event) => setStatus(event.target.value as ListingStatus)} value={status}>
                <option value={ListingStatus.DRAFT}>{t("listing.status.DRAFT", "Draft")}</option>
                <option value={ListingStatus.ARCHIVED}>{t("listing.status.ARCHIVED", "Archived")}</option>
                <option value={ListingStatus.AVAILABLE}>{t("listing.status.AVAILABLE", "Available")}</option>
                <option value={ListingStatus.RENTED}>{t("listing.status.RENTED", "Rented")}</option>
              </Select>
            </FormItem>
          </div>
          <div className="flex justify-end space-x-5 py-12">
            <ButtonSecondary onClick={() => close()}>{t("common.button.cancel", "Cancel")}</ButtonSecondary>
            <ButtonPrimary onClick={() => handleStatusChange(close)}>{t("common.button.change", "Change")}</ButtonPrimary>
          </div>
        </div>
      </>
    )
  }

  const renderActionButtons = () => {
    return (
      <>
        <ButtonSecondary onClick={() => navigate("/manage-listing/" + listing.externalId)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
          <span className="ml-3">{t("common.button.edit", "Edit")}</span>
        </ButtonSecondary>
        <NcModal
          contentExtraClass="max-w-md"
          contentPaddingClass="px-4"
          renderTrigger={(openModal) => (
            <ButtonSecondary
              onClick={() => openModal()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg><span className="ml-3">
                {t("listing.actions.changeStatus.button", "Change Status")}
              </span>
            </ButtonSecondary>
          )}
          renderContent={renderChangeStatusModalContent}
          modalTitle={t("listing.actions.changeStatus.title", "Change Listing Status")}
        />
      </>
    );
  };

  return (
    <CommonListingCard className={className} data={listing} href={href} badges={(
      <Badge
        name={
          <div className="flex items-center">
            <i className="text-sm las la-share-alt"></i>
            <span className="ml-1">{t(`listing.status.${listing.status}`, listing.status as string)}</span>
          </div>
        }
        color={getColorForStatus(listing.status)}
      />
    )}>
      {renderActionButtons()}
    </CommonListingCard>
  );
};

function getColorForStatus(status?: ListingStatus): TwMainColor {
  if (!status) return 'gray';
  switch (status) {
    case 'ARCHIVED':
      return 'gray';
    case 'RENTED':
      return 'red';
    case 'AVAILABLE':
      return 'green';
    case 'DRAFT':
      return 'yellow';
    default:
      return 'gray';
  }
}

export default AdministrationListingCard;
