import ListingCard from 'components/ListingCard/ListingCard';
import { Listing } from 'models/listings';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createApplication } from 'services/application-service';
import { getById } from 'services/listing-service';
import { Alert } from 'shared/Alert/Alert';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

export const ApplicationPage = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [listing, setListing] = useState<Listing | undefined>();
    const navgiate = useNavigate();
    const [apiError, setApiError] = useState<string>();

    useEffect(() => {
        if (id) {
            getById(id).then((response) => {
                setListing(response.data.payload);
            }).catch((error) => {
                console.log(error);

            });
        }
    }, [id]);


    const handleCancel = (): void => {
        navgiate("/listing/" + id);
    }

    const handleOnConfirm = (): void => {
        setApiError(undefined)
        createApplication(id as string).then(() => {
            navgiate("/application-success");
        }).catch((error) => {
            console.log(error);
            setApiError(error.response.data.error.message);
        });
    }

    return (
        <>
            <div className='py-16 px-16'>
                <h1 className='text-2xl font-bold mb-5'>{t("page.createApplication.title", "Application")}</h1>
                {listing && <ListingCard className='hover:shadow-none' listing={listing} href={''} />}

                {/* confirm or cancel */}

                <div className='mt-8 text-center'>

                    <p className='text-neutral-600 dark:text-neutral-300 mt-2'>{t("page.createApplication.description", "Please confirm you want to appy for to the lsiting above")}</p>

                </div>

                <div className="flex space-x-4 justify-center mt-4">
                    <ButtonSecondary onClick={handleCancel} >{t("page.createApplication.button.cancel", "Cancel")}</ButtonSecondary>
                    <ButtonPrimary onClick={handleOnConfirm} >{t("page.createApplication.button.confirm", "Confirm")}</ButtonPrimary>
                </div>

                {/* ERROR */}
                {apiError &&
                    <Alert containerClassName='my-4' type="error">
                        {apiError}
                    </Alert>
                }
            </div>
        </>
    );

}

export default ApplicationPage;