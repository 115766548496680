import { useListings } from "hooks/listings/useListing";
import { PagingAndSortingFilter } from "models/filters";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Pagination from "shared/Pagination/Pagination";
import { getBasicFilterFromSearchParams } from "utils/queryParamFilterUtils";
import AdministrationListingCard from "../../../components/ListingCard/AdministrationListingCard";

const MyListingsPage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<PagingAndSortingFilter>({} as PagingAndSortingFilter);
  const [currentPage, setCurrentPage] = useState(0);
  const { data, error, isLoading } = useListings(filter, [filter]);

  useEffect(() => {
    if (data) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [data]);

  useEffect(() => {
    const newFilter = getBasicFilterFromSearchParams(searchParams);
    setFilter(newFilter);
    if (newFilter.page) {
      setCurrentPage(newFilter.page);
    }
  }, [searchParams]);


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    searchParams.set("page", String(page));
    setSearchParams(searchParams);
  };

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <Helmet>
          <title>{t("page.myListings.helmet", "My Listings")}</title>
        </Helmet>
        <div>
          <h2 className="text-3xl font-semibold">{t("page.myListings.title", "My Listings")}</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex mt-16">

          <div className="pt-2">
            <ButtonPrimary href="/add-listing">{t("page.myListings.button.create", "Create Listing")}</ButtonPrimary>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-1 gap-6 md:gap-8">
            {data?.payload.map((item) => (
              <AdministrationListingCard key={item.externalId} data={item} href={`/listing/${item.externalId}`} />
            ))}
          </div>
          {data && data.totalPages > 1 && (
            <div className="flex mt-16 justify-center items-center">
              <Pagination
                totalPages={data.totalPages}
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900 ">

      <div className="container pt-6 pb-24 lg:pb-32">
        {renderSection1()}
      </div>
    </div>
  );
};

export default MyListingsPage;
