import { NumericRange } from "models/filters";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface PriceRangeInputProps {
  onChange: (e: NumericRange) => void;
  priceRange: NumericRange;
}

const PriceRangeInput: FC<PriceRangeInputProps> = ({
  onChange,
  priceRange,
}) => {
  const { t } = useTranslation();
  const [internalPriceRange, setInternalPriceRange] = useState<NumericRange>(priceRange);

  useEffect(() => { setInternalPriceRange(priceRange); }, [priceRange]);

  function setRangePrices(min: number | null, max: number | null): void {
    setInternalPriceRange({
      min: min,
      max: max,
    });
    onChange({
      min: min,
      max: max,
    });
  }

  return (
    <div className="p-5">
      <span className="block font-semibold text-xl sm:text-2xl">
        {t("page.search.field.price.description", "Choose price range")}
      </span>
      <div className="relative flex flex-col space-y-8 mt-7">

        <div className="flex justify-between space-x-3">
          <div>
            <label
              htmlFor="minPrice"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              {t("common.min", "Min")}
            </label>
            <div className="mt-1 relative rounded-md">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-neutral-500 sm:text-sm">€</span>
              </div>
              <input
                type="number"
                name="minPrice"
                id="minPrice"
                className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 dark:border-neutral-500 rounded-full text-neutral-900 dark:text-neutral-200 bg-transparent"
                value={internalPriceRange.min || undefined}
                onChange={(e) => setRangePrices(e.target.value ? parseInt(e.target.value) : null, internalPriceRange.max)}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="maxPrice"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              {t("common.max", "Max")}
            </label>
            <div className="mt-1 relative rounded-md">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-neutral-500 sm:text-sm">€</span>
              </div>
              <input
                type="number"
                name="maxPrice"
                id="maxPrice"
                className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 dark:border-neutral-500 rounded-full text-neutral-900 dark:text-neutral-200 bg-transparent"
                value={internalPriceRange.max || undefined}
                onChange={(e) => setRangePrices(internalPriceRange.min, e.target.value ? parseInt(e.target.value) : null)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceRangeInput;
