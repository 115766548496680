import Page404 from "containers/404/Page404";
import { useAuth } from "providers/AuthProvider";
import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type ProtectedRouteProps = PropsWithChildren & {
    allowedRoles?: string[];
    loginPath: string;
};


export default function ProtectedRoute({ children, allowedRoles = [], loginPath = "/login" }: ProtectedRouteProps) {
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser === null) {
            navigate(loginPath);
        }
    }, [currentUser]);

    if (currentUser === undefined) {
        return <div>Loading...</div>
    }

    if (currentUser === null) {
        return null;
    }


    if (currentUser && allowedRoles.length > 0) {
        const isAllowed = allowedRoles.includes(currentUser.role || "");
        if (!isAllowed) {
            return <Page404 />;
        }
    }
    return <>{children}</>;
}
