import { AxiosResponse } from "axios";
import { Document } from "models/document";
import { MessageResponse, ResponseObject } from "models/responses";
import { AdminRegistration, FinishRegistrationRequest, LandlordRegistration, StudentRegistration, Token, User, UserCredentails } from "models/users";
import apiClient from "./api-client";

export function login(login: UserCredentails): Promise<AxiosResponse<ResponseObject<Token>>> {
    return apiClient.post<ResponseObject<Token>>('/api/v1/authenticate', login);
}

export function getProfile(): Promise<AxiosResponse<ResponseObject<User>>> {
    return apiClient.get<ResponseObject<User>>('/api/v1/users/profile');
}

export function registerLandlord(registration: LandlordRegistration): Promise<AxiosResponse<ResponseObject<MessageResponse>>> {
    return apiClient.post<ResponseObject<MessageResponse>>('/api/v1/users/landlords/registrations', registration);
}

export function registerStudent(registration: StudentRegistration): Promise<AxiosResponse<ResponseObject<MessageResponse>>> {
    const user = {
        email: registration.email,
        firstName: registration.firstName,
        lastName: registration.lastName
    }

    const formData = new FormData();
    formData.append('user', JSON.stringify(user));
    formData.append('file', registration.proofOfStudy);

    return apiClient.postForm<ResponseObject<MessageResponse>>('/api/v1/users/students/registrations',
        formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    );
}

export function reviewStundetRegistration(externalId: string, approved: boolean): Promise<AxiosResponse<ResponseObject<MessageResponse>>> {
    return apiClient.put<ResponseObject<MessageResponse>>(`/api/v1/users/students/registrations/${externalId}`, null,
        { params: { approved: approved } });
}

export function reviewLandlordRegistration(externalId: string, approved: boolean): Promise<AxiosResponse<ResponseObject<MessageResponse>>> {
    return apiClient.put<ResponseObject<MessageResponse>>(`/api/v1/users/landlords/registrations/${externalId}`, null,
        { params: { approved: approved } });
}


export function downloadDocument(doc: Document): void {
    // apiClient.get(`/api/v1/users/students/registrations/${reference}/document`, { headers
    apiClient.get(`/api/v1/documents/${doc.externalId}`, { responseType: 'blob' })
        .then(response => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', doc.filename);
            document.body.appendChild(link);
            link.click();
            // Clean up and remove the link
            link?.parentNode?.removeChild(link);
        });

}

export function createAdmin(registration: AdminRegistration): Promise<AxiosResponse<ResponseObject<MessageResponse>>> {
    return apiClient.post<ResponseObject<MessageResponse>>('/api/v1/users', registration);
}

export function finishRegistration(request: FinishRegistrationRequest): Promise<AxiosResponse<ResponseObject<User>>> {
    return apiClient.post<ResponseObject<User>>('/api/v1/users/finish-registration', request);
}

export function changeUserActiveStatus(externalId: string, active: boolean): Promise<AxiosResponse<ResponseObject<MessageResponse>>> {
    return apiClient.put<ResponseObject<MessageResponse>>(`/api/v1/users/${externalId}/status`, null, { params: { active: active } });
}