import React, { ReactNode } from 'react';
import ReactLoading from "react-loading";
import { Alert } from 'shared/Alert/Alert';

interface ListResultsInformationProps {
    totalResults?: number;
    error?: string | null;
    label?: string;
    isLoading: boolean;
    className?: string;
}

const ListResultsInformation: React.FC<ListResultsInformationProps> = ({ totalResults, error, isLoading, className, label = "Total results found:" }) => {

    const renderContent = (): ReactNode => {
        if (isLoading) {
            return <ReactLoading type='bars' color="#4f46e5" width="100px" />;
        }

        return (
            <div className={`nc-ListResultsInformation ${className}`}>
                {error && (
                    <div className="w-full">
                        <Alert type="error" >
                            {error}
                        </Alert>
                    </div>
                )}
                {totalResults !== undefined && totalResults !== null && <div className='text-left'>{label} {totalResults}</div>}
            </div>
        );
    }

    return (
        <div className="flex justify-center h-full">
            {renderContent()}
        </div>
    );


}

export default ListResultsInformation;