import { NumericRange } from "models/filters";
import { SearchFilter, UnitType } from "models/listings";
import { FC, FormEvent, useEffect, useState } from "react";
import AreaRangeInput from "./AreaRangeInput";
import ButtonSubmit from "./ButtonSubmit";
import PriceRangeInput from "./PriceRangeInput";
import PropertyTypeSelect from "./PropertyTypeSelect";
import SearchKeywordInput from "./SearchKeywordInput";
import { useTranslation } from "react-i18next";

export interface ListingSearchFormProps {
  filter: SearchFilter;
  onFilterChange?: (filter: SearchFilter) => void;
}

const ListingSearchForm: FC<ListingSearchFormProps> = ({ filter, onFilterChange }) => {
  const { t } = useTranslation();
  const [searchKeyword, setSearchKeyword] = useState<string | undefined>(filter.keyword);
  const [priceRange, setPriceRange] = useState<NumericRange>(filter.price || { min: null, max: null });
  const [areaRange, setAreaRange] = useState<NumericRange>(filter.area || { min: null, max: null });
  const [unitTypes, setUnitTypes] = useState<UnitType[]>(filter.unitTypes || []);

  useEffect(() => {
    setSearchKeyword(filter.keyword);
    setPriceRange(filter.price || { min: null, max: null });
    setAreaRange(filter.area || { min: null, max: null });
    setUnitTypes(filter.unitTypes || []);
  }, [filter]);


  function onSearch(): void {
    onFilterChange?.({ keyword: searchKeyword, price: priceRange, area: areaRange, unitTypes: unitTypes } as SearchFilter);
  }

  function priceRangeChanged(newRange: NumericRange): void {
    setPriceRange(newRange);
    if (onFilterChange) {
      onFilterChange?.({ keyword: searchKeyword, price: newRange, area: areaRange, unitTypes: unitTypes } as SearchFilter);
    }
  }

  function areaRangeChanged(newRange: NumericRange): void {
    setAreaRange(newRange);
    if (onFilterChange) {
      onFilterChange?.({ keyword: searchKeyword, price: priceRange, area: newRange, unitTypes: unitTypes } as SearchFilter);
    }
  }

  const onSearchKeywordChanged = (keyword: string): void => {
    setSearchKeyword(keyword);
  }


  function handleOnPropertyTypeChange(data: UnitType[]): void {
    setUnitTypes(data);
    console.log(data);
    if (onFilterChange) {
      onFilterChange({ keyword: searchKeyword, price: priceRange, area: areaRange, unitTypes: data } as SearchFilter);
    }
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    onSearch();
  }

  const renderForm = () => {
    return (
      <form className="w-full relative xl:mt-8 flex flex-col lg:flex-row lg:items-center rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0" onSubmit={handleSubmit}>
        <SearchKeywordInput desc={t("page.search.field.search.description", "")} searchKeyword={searchKeyword} onValueChange={onSearchKeywordChanged} className="flex-[1.5]" />

        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <PropertyTypeSelect unitTypes={unitTypes}  onChange={handleOnPropertyTypeChange} />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <AreaRangeInput areaRange={areaRange} onAreaRangeChanged={areaRangeChanged} />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <PriceRangeInput priceRange={priceRange} onPriceRangeChanged={priceRangeChanged} />
        {/* BUTTON SUBMIT OF FORM */}
        <div className="pr-2 xl:pr-4">
          <ButtonSubmit onClick={() => onSearch()} />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default ListingSearchForm;
