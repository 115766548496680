import { StudentRegistration } from "models/users";
import { FC, FormEvent, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { registerStudent } from "services/user-service";
import { Alert } from "shared/Alert/Alert";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

export interface PageSignUpProps {
  className?: string;
}


const PageStudentSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({} as StudentRegistration);
  const [errors, setErrors] = useState<{ email?: string; firstName?: string; lastName?: string }>({});
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string | null>(null);

  function validateForm(): boolean {
    const newErrors: { email?: string; firstName?: string; lastName?: string } = {};
    if (!formData.email) {
      newErrors.email = t("common.validation.email", "Email is required");
    }
    if (!formData.firstName) {
      newErrors.firstName = t("common.validation.required", "First name is required");
    }
    if (!formData.lastName) {
      newErrors.lastName = t("common.validation.required", "Last name is required");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setApiError(null);
      console.log(formData);
      registerStudent(formData).then((res) => {
        console.log(res.data);
        // navigate to signup success page
        navigate("/signup-success");
      }).catch((err) => {
        setApiError(err.response.data.error.message);

      });
    }
  };


  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      setFormData({
        ...formData,
        proofOfStudy: e.target.files[0]
      });
    }
  }


  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>{t("page.signup.helmet", "Sign Up")}</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("page.signup.title", "Sign Up")}
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t("common.field.email", "Email address")}
              </span>
              <Input
                type="email"
                className="mt-1"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t("user.firstName", "First Name")}
              </span>
              <Input
                type="text"
                className="mt-1"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
              {errors.firstName && <p className="text-red-500">{errors.firstName}</p>}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t("user.lastName", "Last Name")}
              </span>
              <Input
                type="text"
                name="lastName"
                className="mt-1"
                value={formData.lastName}
                onChange={handleChange}
              />
              {errors.lastName && <p className="text-red-500">{errors.lastName}</p>}
            </label>

            <div>
              <span className="text-lg font-semibold">{t("registrationRequest.proofOfStudy", "Proof of Study")}</span>
              <div className="mt-5 ">
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-neutral-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                      >
                        <span>{t("components.upload.button", "Upload a file")}</span>
                        <input
                          id="file-upload"
                          name="proofOfStudy"
                          type="file"
                          className="sr-only"
                          value={undefined}
                          onChange={handleFileChange}
                        />
                      </label>
                      <p className="pl-1">{t("components.upload.drag", "or drag and drop")}</p>
                    </div>
                    <p className="text-xs text-neutral-500 dark:text-neutral-400">
                      {t("components.upload.description", "Images and PDF up to 10MB")}
                    </p>
                    {formData.proofOfStudy && (
                      <div>
                        <h4> {t("components.upload.selected", "Selected file:")} {formData.proofOfStudy.name}</h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <ButtonPrimary type="submit"> {t("page.signup.button.signup", "Sign Up")}</ButtonPrimary>
          </form>

          {/* ERROR */}
          {apiError &&
            <Alert type="error">
              {apiError}
            </Alert>
          }
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t("page.signup.alreadyHaveAccount", "Already have an account?")} {` `}
            <Link to="/login"> {t("common.button.login", "Log In")}</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageStudentSignUp;


