import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";






export const getNavigation = (role: string | "STUDENT" | "ADMIN" | "LANDLORD" | null | undefined): NavItemType[] => {

  const guestNav = [
    {
      id: ncNanoId(),
      href: "/",
      name: "Home",
      localizationKey: "nav.home",
    }
  ] as NavItemType[];

  if (role === null || role === undefined) {
    return guestNav

  }
  switch (role) {
    case "STUDENT":
      return [
        {
          id: ncNanoId(),
          href: "/",
          name: "Home",
          localizationKey: "nav.home",
        },
        {
          id: ncNanoId(),
          href: "/my-applications",
          localizationKey: "nav.myApplications",
          name: "My Applications",
        },
      ];
    case "ADMIN":
      return [
        {
          id: ncNanoId(),
          href: "/",
          name: "Home",
          localizationKey: "nav.home",
        },
        {
          id: ncNanoId(),
          href: "/users",
          name: "Users",
          localizationKey: "nav.users",
        }, {
          id: ncNanoId(),
          href: "/registration-requests",
          name: "Registration Requests",
          localizationKey: "nav.registrationRequests",
        }, {
          id: ncNanoId(),
          href: "/student-applications",
          name: "Student Applications",
          localizationKey: "nav.studentApplications",
        },
      ];
    case "LANDLORD":
      return [
        {
          id: ncNanoId(),
          href: "/",
          name: "Home",
          localizationKey: "nav.home",
        },
        {
          id: ncNanoId(),
          href: "/my-listings",
          name: "My Listings",
          localizationKey: "nav.myListings",
        }, {
          id: ncNanoId(),
          href: "/applications",
          name: "Applications",
          localizationKey: "nav.applications",
        }
      ];
    default:
      return guestNav;
  }


}