import ListingCard from "components/ListingCard/ListingCard";
import { Listing } from "models/listings";
import { PaginatedRespoonseList } from "models/responses";
import { FC } from "react";
import Pagination from "shared/Pagination/Pagination";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: PaginatedRespoonseList<Listing> | null;
  currentPage?: number;
  onCurrentPageChange?: (page: number) => void;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data: searchResponse,
  onCurrentPageChange,
  currentPage = 0
}) => {
  // fetch data from backend

  // define data

  function currentPageChanged(page: number): void {
    if (onCurrentPageChange) {
      onCurrentPageChange(page);
    }
  }

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <div className="grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols- ">
        {searchResponse?.payload.map((listing) => (
          <ListingCard key={listing.externalId} listing={listing} href={'/listing/' + listing.externalId} />
        ))}
      </div>
      {searchResponse && searchResponse.totalPages > 1 && (
        <div className="flex mt-16 justify-center items-center">
          <Pagination
            totalPages={searchResponse.totalPages}
            currentPage={currentPage}
            setCurrentPage={currentPageChanged}
          />
        </div>
      )}
    </div>
  );
};

export default SectionGridFilterCard;