import { NumericRange } from "models/filters";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface AreaRangeInputProps {
  onChange: (e: NumericRange) => void;
  areaRange: NumericRange;
}

const AreaRangeInput: FC<AreaRangeInputProps> = ({
  onChange,
  areaRange,
}) => {
  const { t } = useTranslation();
  const [internalAreaRange, setInternalAreaRange] = useState<NumericRange>(areaRange);

  useEffect(() => { setInternalAreaRange(areaRange); }, [areaRange]);

  function setRangeAreas(min: number | null, max: number | null): void {
    setInternalAreaRange({
      min: min,
      max: max,
    });
    onChange({
      min: min,
      max: max,
    });
  }

  return (
    <div className="p-5">
      <span className="block font-semibold text-xl sm:text-2xl">
        {t("page.search.field.area.description", "Choose area range")}
      </span>
      <div className="relative flex flex-col space-y-8 mt-7">

        <div className="flex justify-between space-x-3">
          <div>
            <label
              htmlFor="minArea"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              {t("common.min", "Min")}
            </label>
            <div className="mt-1 relative rounded-md">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-neutral-500 sm:text-sm">m²</span>
              </div>
              <input
                type="number"
                name="minArea"
                id="minArea"
                className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 pr-3 sm:text-sm border-neutral-200 dark:border-neutral-500 rounded-full text-neutral-900 dark:text-neutral-200 bg-transparent"
                value={internalAreaRange.min || undefined}
                onChange={(e) => setRangeAreas(e.target.value ? parseInt(e.target.value) : null, internalAreaRange.max)}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="maxArea"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              {t("common.max", "Max")}
            </label>
            <div className="mt-1 relative rounded-md">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-neutral-500 sm:text-sm">m²</span>
              </div>
              <input
                type="number"
                name="maxArea"
                id="maxArea"
                className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 pr-3 sm:text-sm border-neutral-200 dark:border-neutral-500 rounded-full text-neutral-900 dark:text-neutral-200 bg-transparent"
                value={internalAreaRange.max || undefined}
                onChange={(e) => setRangeAreas(internalAreaRange.min, e.target.value ? parseInt(e.target.value) : null)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaRangeInput;
