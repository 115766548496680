import { useGetData } from "hooks/useData";
import { Application } from "models/applications";
import { PagingAndSortingFilter } from "models/filters";
import { PaginatedRespoonseList, ResponseObject } from "models/responses";

export const useApplications = (filter: PagingAndSortingFilter, deps?: any[]) => {
    const endpoint = `/api/v1/applications`;

    const { data, error, isLoading } = useGetData<PaginatedRespoonseList<Application>>(endpoint, { params: filter || {} }, deps);

    return { data, error, isLoading };
};


export const useRental = (externalId: string, deps?: any[]) => {
    const endpoint = `/api/v1/applications/${externalId}`;

    const { data, error, isLoading } = useGetData<ResponseObject<Application>>(endpoint, undefined, deps);

    return { data, error, isLoading };
};
