import { useAmenities } from "hooks/listings/useAmenities";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "shared/Checkbox/Checkbox";
import { getAmenity } from "utils/getAmenity";
import CommonLayout from "./CommonLayout";
import { PropertyAmenities } from "./PageManageListing";


export interface ManageListingAmenitiesProps {
  formData: PropertyAmenities;
  onNextPage?: (data: PropertyAmenities) => void;
  onBackPage?: (data: PropertyAmenities) => void;
  index: string;
  maxIndex: string;
}

const ManageListingAmenities: FC<ManageListingAmenitiesProps> = ({ onBackPage, onNextPage, formData, index, maxIndex }) => {
  const { t } = useTranslation();
  const [internalFormData, setInternalFormData] = useState(formData);
  const { amenities } = useAmenities();

  useEffect(() => {
    setInternalFormData(formData);
  }, [formData]);

  const handleOnNextPage = () => {
    if (onNextPage)
      onNextPage(internalFormData);
  }

  const handleOnBackPage = () => {
    if (onBackPage)
      onBackPage(internalFormData);
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    let copy = {} as PropertyAmenities;
    if (internalFormData.amenities)
      copy = { amenities: [...internalFormData.amenities] };
    else {
      copy = { amenities: [] };
    }
    if (checked) {
      // add to amentites list
      if (!copy.amenities.includes(name))
        copy.amenities.push(name);
    } else {
      // remove from amentites list
      const index = copy.amenities.indexOf(name);
      if (index > -1) {
        copy.amenities.splice(index, 1);
      }
    }

    setInternalFormData(copy);
  }

  return (
    <CommonLayout
      index={index}
      maxIndex={maxIndex}
      onBackPage={handleOnBackPage}
      onNextPage={handleOnNextPage}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">{t("page.manageListings.amenities.title", "Amenities")}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t("page.manageListings.amenities.description", "Select amenities that are available in your listing.")}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <div>
            <label className="text-lg font-semibold" htmlFor="">
              {t("page.manageListings.amenities.subtitle", "Available Amenities")}
            </label>
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
              {amenities.map((amenity) => (
                <Checkbox key={amenity} name={amenity} label={getAmenity(t, amenity)} defaultChecked={internalFormData?.amenities?.includes(amenity)} onChange={handleCheckboxChange} />
              ))}
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default ManageListingAmenities;
