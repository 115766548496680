import { useEffect, useState } from "react";
import apiClient from "services/api-client";

const DATA = ["AIR_CONDITIONING", "BALCONY", "FURNISHED", "GARDEN", "PARKING", "SWIMMING_POOL", "WIFI"];




export const useAmenities = () => {
    const [amenities, setAmenities] = useState<string[]>([]);
    useEffect(() => {
        apiClient.get("/api/v1/commons/amenities").then((response) => {
            setAmenities(response.data.payload);
        }).catch((error) => {
            console.log(error);
            setAmenities(DATA);
        });
    }, []);

    return { amenities };
}