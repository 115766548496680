import { Popover, Transition } from "@headlessui/react";
import { HomeIcon } from "@heroicons/react/24/outline";
import { UnitType } from "models/listings";
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "shared/Checkbox/Checkbox";

export interface PropertyTypeSelectProps {
  unitTypes: UnitType[],
  onChange?: (data: UnitType[]) => void;
  fieldClassName?: string;
}

const PropertyTypeSelect: FC<PropertyTypeSelectProps> = ({
  unitTypes,
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const { t } = useTranslation();
  const types = [UnitType.APARTMENT, UnitType.HOUSE, UnitType.ROOM];
  const [typeOfProperty, setTypeOfProperty] = useState<UnitType[]>(unitTypes);

  useEffect(() => {
    setTypeOfProperty(unitTypes);
  }, [unitTypes]);

  let typeOfPropertyText = "";
  if (typeOfProperty && typeOfProperty.length > 0) {
    typeOfPropertyText = typeOfProperty.map(type => t(`listing.propertyTypes.${type}`, type)).join(", ");
  }

  const handleTypeChange = (checked: boolean, item: UnitType) => {
    let copy = [...(typeOfProperty || [])];
    if (checked) {
      copy.push(item);
      setTypeOfProperty((prev) => {
        if (!prev?.includes(item)) {
          return [...(prev || []), item];
        }
        return prev;
      });
    } else {
      if (copy.includes(item)) {
        copy.splice(copy.indexOf(item), 1);
      }
      setTypeOfProperty((prev) => {
        return (prev || []).filter((i) => i !== item);
      });
    }
    if (onChange) {
      onChange(copy);
    }
  }


  return (
    <Popover className="flex relative flex-1">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex z-10 text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${open ? "nc-hero-field-focused" : ""
              }`}
            onClickCapture={() => document.querySelector("html")?.click()}
          >
            <div className="text-neutral-300 dark:text-neutral-400">
              <HomeIcon className="w-5 h-5 lg:w-7 lg:h-7" />
            </div>
            <div className="flex-1">
              <span className="block xl:text-lg font-semibold overflow-hidden">
                <span className="line-clamp-1">
                  {typeOfPropertyText || t("common.any", "Any")}
                </span>
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light ">
                {t("page.search.propertyType.description", "Property Type")}
              </span>
            </div>
          </Popover.Button>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <div className="">
                <div className="relative flex flex-col space-y-5">
                  {types.map((item, index) => (
                    <div key={item} className="">
                      <Checkbox
                        onChange={checked => handleTypeChange(checked.target.checked, item)}
                        name={item}
                        label={t(`listing.propertyTypes.${item}`, item)}
                        defaultChecked={typeOfProperty?.includes(item)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PropertyTypeSelect;
