

import { useUsers } from "hooks/users/useUser";
import { PagingAndSortingFilter } from "models/filters";
import { AdminRegistration, User, UserStatus } from "models/users";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { changeUserActiveStatus, createAdmin } from "services/user-service";
import { Alert } from "shared/Alert/Alert";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import { ConfirmationModal } from "shared/Modal/ConfirmationModal";
import NcModal from "shared/NcModal/NcModal";
import Pagination from "shared/Pagination/Pagination";
import { getUserRole } from "utils/getUserRole";
import { getUserStatus } from "utils/getUserStatus";
import { getBasicFilterFromSearchParams } from "utils/queryParamFilterUtils";

const UserList = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [changeTrigger, setChangeTrigger] = useState(0);
    const [filter, setFilter] = useState<PagingAndSortingFilter>({} as PagingAndSortingFilter);
    const [currentPage, setCurrentPage] = useState(0);
    const { data, error, isLoading } = useUsers(filter, [filter, changeTrigger]);
    const [newAdmin, setNewAdmin] = useState({} as AdminRegistration);
    const [apiError, setApiError] = useState<string | null>(null);

    function triggerChange() {
        setChangeTrigger(changeTrigger + 1);
    }

    useEffect(() => {
        if (data) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [data]);

    useEffect(() => {
        const newFilter = getBasicFilterFromSearchParams(searchParams);
        setFilter(newFilter);
        if (newFilter.page) {
            setCurrentPage(newFilter.page);
        }
    }, [searchParams]);


    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    function handleChange(event: ChangeEvent<HTMLInputElement>): void {
        setNewAdmin({
            ...newAdmin,
            [event.target.name]: event.target.value
        });
    }

    const handleAdminSave = (close: Function) => {
        setApiError(null);
        createAdmin(newAdmin).then(() => {
            triggerChange();
            close();
        }).catch((error) => {
            console.error(error);
            setApiError(error.response.data.error.message);
        });
    }

    const changeUserStatus = (user: User, active: boolean) => {
        changeUserActiveStatus(user.externalId, active).then(() => {
            triggerChange();
        }).catch((error) => {
            console.error(error);
            setApiError(error.response.data.error.message);
        });
    }

    const renderChangeStatusModalContent = (close: Function) => {
        return (
            <>
                <div className="px-4 max-w-md mx-auto pt-14">
                    {/* FORM */}
                    <div className="space-y-8">
                        {/* FORM */}
                        <form className="grid grid-cols-1 gap-6" onSubmit={() => handleAdminSave(close)}>
                            <label className="block">
                                <span className="text-neutral-800 dark:text-neutral-200">
                                    {t("user.email", "Email address")}
                                </span>
                                <Input
                                    type="email"
                                    className="mt-1"
                                    name="email"
                                    value={newAdmin.email}
                                    onChange={handleChange}
                                />
                            </label>
                            <label className="block">
                                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                    {t("user.firstName", "First Name")}
                                </span>
                                <Input
                                    type="text"
                                    name="firstName"
                                    className="mt-1"
                                    value={newAdmin.firstName}
                                    onChange={handleChange}
                                />
                            </label>
                            <label className="block">
                                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                    {t("user.lastName", "Last Name")}
                                </span>
                                <Input
                                    type="text"
                                    name="lastName"
                                    className="mt-1"
                                    value={newAdmin.lastName}
                                    onChange={handleChange}
                                />
                            </label>
                        </form>

                        {/* ERROR */}
                        {apiError &&
                            <Alert type="error">
                                {apiError}
                            </Alert>
                        }
                    </div>
                    <div className="flex justify-end space-x-5 py-12">
                        <ButtonSecondary onClick={() => close()}>{t("common.button.cancel", "Cancel")}</ButtonSecondary>
                        <ButtonPrimary onClick={() => handleAdminSave(close)}>{t("common.button.save", "Save")}</ButtonPrimary>
                    </div>
                </div>
            </>
        )
    }

    const renderUserStatusChangeButton = (user: User): ReactNode => {
        if (user.status === UserStatus.REGISTRATION_PENDING) {
            return null;
        }
        const action = user.status === UserStatus.INACTIVE;
        const actionText = action ? t("user.actions.activate.button", 'Activate') : t("user.actions.deactivate.button", 'Deactivate');
        const title = action ? t("user.actions.activate.title", 'Activate User?') : t("user.actions.deactivate.title", 'Deactivate User?');
        const message = action ? t("user.actions.activate.description", 'Are you sure you want to activate this user?') : t("user.actions.deactivate.description", 'Are you sure you want to deactivate this user?');
        const icon = action ? 'las la-unlock' : 'las la-lock';
        const colorClass = action ? 'hover:bg-green-300 hover:dark:bg-green-800' : 'hover:bg-red-300 hover:dark:bg-red-800';
        return (
            <ConfirmationModal confirmText={actionText} cancelText={t("common.button.cancel", "Cancel")} title={title}
                message={message}
                onConfirm={() => changeUserStatus(user, user.status === UserStatus.INACTIVE)}
                onCancel={() => { }}
                renderTrigger={(openModal: Function) => {
                    return (
                        <>
                            <ButtonSecondary className={colorClass} onClick={() => openModal()}>
                                <i className={icon}></i>
                                <span className="pl-2">{actionText}</span>
                            </ButtonSecondary>
                        </>
                    )
                }
                } />
        )
    }

    const renderSection1 = () => {
        return (
            <div className="space-y-6 sm:space-y-8">
                <Helmet>
                    <title>{t("page.users.helmet", "Users")}</title>
                </Helmet>
                <div>
                    <h2 className="text-3xl font-semibold">{t("page.users.title", "Users")}</h2>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex mt-4">
                    <div className="pt-2">
                        <NcModal
                            contentExtraClass="max-w-md"
                            contentPaddingClass="px-4"
                            renderTrigger={(openModal) => (
                                <ButtonPrimary
                                    onClick={() => openModal()}
                                >
                                    {t("page.users.createAdmin.button", "Create Administrator")}
                                </ButtonPrimary>
                            )}
                            renderContent={renderChangeStatusModalContent}
                            modalTitle={t("page.users.createAdmin.title", "Create Administrator")}
                        />
                    </div>
                </div>
                <div className="group relative  bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden p-10">
                    <div className="relative overflow-x-auto sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 rounded-3xl">
                                <tr>
                                    <th scope="col" className="px-6 py-3" >{t("user.firstName", "First Name")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("user.lastName", "Last Name")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("user.email", "Email")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("user.role", "Role")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("page.users.table.status", "Status")}</th>
                                    <th scope="col" className="px-6 py-3" ></th>
                                </tr>
                            </thead>

                            <tbody>
                                {data?.payload.map((item) => (
                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                        <td className="px-6 py-4" >{item.firstName}</td>
                                        <td className="px-6 py-4">{item.lastName}</td>
                                        <td className="px-6 py-4">{item.email}</td>
                                        <td className="px-6 py-4">{getUserRole(t, item.role)}</td>
                                        <td className="px-6 py-4">
                                            <Badge name={getUserStatus(t, item.status)} color={item.status === UserStatus.ACTIVE ? 'green' : item.status === UserStatus.INACTIVE ? 'red' : 'yellow'} />
                                        </td>
                                        <td className="px-6 py-4">
                                            {renderUserStatusChangeButton(item)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {data && data.totalPages > 1 && (
                        <div className="flex mt-16 justify-center items-center">
                            <Pagination
                                totalPages={data.totalPages}
                                currentPage={currentPage}
                                setCurrentPage={handlePageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900 ">
            <div className="container pt-6 pb-24 lg:pb-32">
                {renderSection1()}
            </div>
        </div>
    );
};

export default UserList;
