import { useGetData } from "hooks/useData";
import { PagingAndSortingFilter } from "models/filters";
import { PaginatedRespoonseList } from "models/responses";
import { LandlordRegistrationRequest, StudentRegistrationRequest, User } from "models/users";

export const useUsers = (filter: PagingAndSortingFilter, deps?: any[]) => {
    const endpoint = `/api/v1/users`;

    const { data, error, isLoading } = useGetData<PaginatedRespoonseList<User>>(endpoint, { params: filter || {} }, deps);

    return { data, error, isLoading };
};

export const useStudentRegistrations = (filter: PagingAndSortingFilter, deps?: any[]) => {
    const endpoint = `/api/v1/users/students/registrations`;

    const { data, error, isLoading } = useGetData<PaginatedRespoonseList<StudentRegistrationRequest>>(endpoint, { params: filter || {} }, deps);

    return { data, error, isLoading };
};


export const useLandlordRegistrations = (filter: PagingAndSortingFilter, deps?: any[]) => {
    const endpoint = `/api/v1/users/landlords/registrations`;

    const { data, error, isLoading } = useGetData<PaginatedRespoonseList<LandlordRegistrationRequest>>(endpoint, { params: filter || {} }, deps);

    return { data, error, isLoading };
};