import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import IBecomeAnAuthor from "images/BecomeAnAuthorImg.png";
import { Alert } from "shared/Alert/Alert";

const RegistrationFinishedPage: React.FC = () => (
  <div className="nc-RegistrationFinishedPage">
    <Helmet>
      <title>Registration Finished</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-2">
        <NcImage src={IBecomeAnAuthor} />
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium py-8">
          <Alert type="success">
            Your registration has been completed successfully!
          </Alert>
        </span>
        <div>
          <ButtonPrimary href="/login">Login</ButtonPrimary>
        </div>
      </header>
    </div>
  </div>
);

export default RegistrationFinishedPage;
