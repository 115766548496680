import { Listing } from "models/listings";
import { FC } from "react";
import CommonListingCard from "./CommonListingCard";

export interface ListingCardProps {
  className?: string;
  listing: Listing;
  href: string;
}

const ListingCard: FC<ListingCardProps> = ({
  className = "",
  listing,
  href
}) => {

  return (
    <CommonListingCard className={className} data={listing} href={href} />
  );
};

export default ListingCard;
