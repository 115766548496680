

import { ReactNode } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import NcModal from 'shared/NcModal/NcModal'

interface IConfirmationModalProps {
    renderTrigger?: (openModal: Function) => ReactNode;
    onConfirm: () => void
    onCancel: () => void
    title: string
    message: string
    confirmText?: string
    cancelText?: string
    className?: string
}

export const ConfirmationModal = ({ renderTrigger, onConfirm, onCancel, title, message, confirmText, cancelText, className = "max-w-md" }: IConfirmationModalProps) => {
    const renderConfirmationContent = (close: Function) => {
        return (
            <>
                <div className="text-center">
                    <p className="mt-2 text-neutral-500">{message}</p>
                </div>
                <div className="flex justify-center mt-6 space-x-4">
                    <ButtonSecondary
                        onClick={() => {
                            onCancel();
                            close();
                        }}
                    >
                        {cancelText || "Cancel"}
                    </ButtonSecondary>
                    <ButtonPrimary
                        onClick={() => {
                            onConfirm();
                            close();
                        }}
                    >
                        {confirmText || "Confirm"}
                    </ButtonPrimary>
                </div>

            </>
        );
    }

    return (
        <NcModal
            contentExtraClass={className}
            renderTrigger={renderTrigger}
            modalTitle={title}
            renderContent={renderConfirmationContent}
        />
    )
}