import { TwMainColor } from "data/types";
import { ApprovalStatus } from "models/commons";

export function getBadgeColorForAproovalStatus(status: ApprovalStatus): TwMainColor {
    switch (status) {
        case ApprovalStatus.PENDING:
            return "yellow";
        case ApprovalStatus.APPROVED:
            return "green";
        case ApprovalStatus.REJECTED:
            return "red";
    }
}
