import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClick?: () => void;
  onValueChange?: (value: string) => void;
  className?: string;
  searchKeyword?: string;
  headingText?: string;
}

const SearchKeywordInput: FC<Props> = ({
  onValueChange = () => { },
  className = "",
  searchKeyword,
  headingText = "Where to?",
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(searchKeyword);
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(searchKeyword);
  }, [searchKeyword]);

  const handlhandleValueChange = (keyword: string) => {
    setValue(keyword);
    if (onValueChange)
      onValueChange(keyword);
  }

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={t("page.search.field.search.placeholder", "Search")}
            value={value}
            onChange={(e) => handlhandleValueChange(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default SearchKeywordInput;
