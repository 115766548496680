import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { PropertyPrice, PropertyPriceValidationError } from "./PageManageListing";

export interface ManageListingPriceProps {
  formData: PropertyPrice;
  onNextPage?: (data: PropertyPrice) => void;
  onBackPage?: (data: PropertyPrice) => void;
  index: string;
  maxIndex: string;
  validationErrors: PropertyPriceValidationError;
}

const ManageListingPrice: FC<ManageListingPriceProps> = ({ onBackPage, onNextPage, formData, index, maxIndex, validationErrors }) => {
  const { t } = useTranslation();
  const [internalFormData, setInternalFormData] = useState<PropertyPrice>(formData);

  const handleOnNextPage = () => {
    if (onNextPage)
      onNextPage(internalFormData);
  }

  const handleOnBackPage = () => {
    if (onBackPage)
      onBackPage(internalFormData);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInternalFormData({
      ...internalFormData,
      [e.target.name]: e.target.value
    });
  }


  return (
    <CommonLayout
      index={index}
      maxIndex={maxIndex}
      onBackPage={handleOnBackPage}
      onNextPage={handleOnNextPage}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">{t("page.manageListings.price.title", "Pricing")}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t("page.manageListings.price.description", "Set the price for your listing")}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem label={t("page.manageListings.price.field.baseRent.label", "Base Rent")}>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">€</span>
              </div>
              <Input name="baseRent" value={internalFormData.baseRent} onChange={handleChange} className="!pl-8 !pr-10" placeholder="0.00" />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">EUR</span>
              </div>
            </div>
            {validationErrors.baseRent && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.baseRent}</span>}
          </FormItem>
          {/* ----- */}
          <FormItem label={t("page.manageListings.price.field.ancilaryCost.label", "Ancilary Costs")}>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">€</span>
              </div>
              <Input name="ancillaryCost" value={internalFormData.ancillaryCost} onChange={handleChange} className="!pl-8 !pr-10" placeholder="0.00" />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">EUR</span>
              </div>
            </div>
            {validationErrors.ancillaryCost && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.ancillaryCost}</span>}
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );
};

export default ManageListingPrice;
