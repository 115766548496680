import axios from "axios";
import { setupAuthIntecptors } from "./http-interceptors";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});


setupAuthIntecptors(http);

export default http;