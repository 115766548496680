import { TFunction } from "i18next";
import { NumericRange } from "models/filters";
import { ReactNode } from "react";


export function renderAreaRange(t: TFunction, range?: NumericRange): ReactNode {
    if (!range) {
        return t("common.any", "Any");
    }
    if (range.min === null && range.max === null) {
        return t("common.any", "Any");
    }
    if (range.min === null) {
        return `${t("common.upTo", "Up to")} ${range.max} ${t("common.m2", "m²")}`;
    }
    if (range.max === null) {
        return `${t("common.from", "From")} ${range.min} ${t("common.m2", "m²")}`;
    }
    return `${range.min} - ${range.max} m²`;
}