import { useApplications } from "hooks/applications/useApplication";
import { PagingAndSortingFilter } from "models/filters";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { deleteApplication } from "services/application-service";
import ListResultsInformation from "shared/ListResultsInformation/ListResultsInformation";
import Pagination from "shared/Pagination/Pagination";
import { getBasicFilterFromSearchParams } from "utils/queryParamFilterUtils";
import MyApplicationCard from "./MyApplicationCard";

const MyApplicationsPage = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState<PagingAndSortingFilter>({} as PagingAndSortingFilter);
    const [currentPage, setCurrentPage] = useState(0);
    const { data, error, isLoading } = useApplications(filter, [filter]);

    useEffect(() => {
        if (data) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [data]);

    useEffect(() => {
        const newFilter = getBasicFilterFromSearchParams(searchParams);
        setFilter(newFilter);
        if (newFilter.page) {
            setCurrentPage(newFilter.page);
        }
    }, [searchParams]);


    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleApplicationDeleted = (id: string) => {
        deleteApplication(id).then(() => {
            handlePageChange(currentPage);
        }).catch((error) => {
            console.error(error);
        });
    }

    const renderSection1 = () => {

        return (
            <div className="space-y-6 sm:space-y-8">
                <div>
                    <h2 className="text-3xl font-semibold">{t("page.myApplications.title", "My Appplications")}</h2>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <ListResultsInformation label={t("page.myApplications.results.description", "Number of applications: ")} totalResults={data?.totalSize} isLoading={isLoading} error={error} className="py-4 w-full" />

                <div>
                    <div className="grid grid-cols-1 gap-6 md:gap-8">
                        {data?.payload.map((item) => (
                            <MyApplicationCard key={item.externalId} handleApplicationDeleted={() => handleApplicationDeleted(item.externalId)} application={item} href={`/listing/${item.listing.externalId}`} />
                        ))}
                    </div>
                    {data && data.totalPages > 1 && (
                        <div className="flex mt-16 justify-center items-center">
                            <Pagination
                                totalPages={data.totalPages}
                                currentPage={currentPage}
                                setCurrentPage={handlePageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900 ">

            <div className="container pt-6 pb-24 lg:pb-32">
                {renderSection1()}
            </div>
        </div>

    );
};

export default MyApplicationsPage;
