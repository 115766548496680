import { useAuth } from "providers/AuthProvider";
import { useEffect, useState } from "react";
import NavigationItem, { NavItemType } from "./NavigationItem";
import { getNavigation } from "data/navigation";

function Navigation() {
  const { currentUser } = useAuth();
  const [menuItems, setMenuItems] = useState<NavItemType[]>([]);

  useEffect(() => {
    setMenuItems(getNavigation(currentUser?.role));
  }, [currentUser]);

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {menuItems?.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
