import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { PropertyDetails, PropertyDetailsValidationError } from "./PageManageListing";
import { UnitType } from "models/listings";

export interface ManageListingDetailsProps {
  formData: PropertyDetails
  onNextPage?: (formData: PropertyDetails) => void;
  onBackPage?: (formData: PropertyDetails) => void;
  index: string;
  maxIndex: string;
  validationErrors: PropertyDetailsValidationError;
}

const ManageListingDetails: FC<ManageListingDetailsProps> = ({ onBackPage, onNextPage, formData, index, maxIndex, validationErrors }) => {
  const { t } = useTranslation();
  const [internalFormData, setInternalFormData] = useState<PropertyDetails>(formData);
  const unitTypes = [UnitType.APARTMENT, UnitType.HOUSE, UnitType.ROOM];
  useEffect(() => {
    setInternalFormData(formData);
  }, [formData]);

  const handleOnNextPage = () => {
    if (onNextPage)
      onNextPage(internalFormData);
  }

  const handleOnBackPage = () => {
    if (onBackPage)
      onBackPage(internalFormData);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInternalFormData({
      ...internalFormData,
      [e.target.name]: e.target.value
    });
  }

  function handleSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    setInternalFormData({
      ...internalFormData,
      [e.target.name]: e.target.value
    });
  }


  return (
    <CommonLayout
      index={index}
      maxIndex={maxIndex}
      onNextPage={handleOnNextPage}
      onBackPage={handleOnBackPage}
    >
      <>
        <h2 className="text-2xl font-semibold">{t("page.manageListings.details.title", "Listing Details")}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label={t("page.manageListings.details.field.propertyType.label", "Listing Details")}
            desc={t("page.manageListings.details.field.propertyType.description", "Please provide the following information about your listing")}
          >
            <Select name="propertyType" onChange={handleSelect} value={internalFormData.propertyType} >
              {unitTypes.map((type) => (
                <option value={type}>{t(`listing.propertyTypes.${type}`, type)}</option>
              ))}

            </Select>
            {validationErrors.propertyType && <p className="text-red-500 text-xs">{validationErrors.propertyType}</p>}
          </FormItem>
          <FormItem
            label={t("page.manageListings.details.field.title.label", "Title")}
            desc={t("page.manageListings.details.field.title.description", "Give your listing a title")}
          >
            <Input placeholder="Places name" onChange={handleChange} value={internalFormData.title} name="title" />
            {validationErrors.title && <p className="text-red-500 text-xs">{validationErrors.title}</p>}
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );
};

export default ManageListingDetails;
